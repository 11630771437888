import React, { Component } from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;

class Contact extends Component {
  render() {
    return (
        <div>
          <h2>Contact</h2>
          <Title>Styled Title</Title>
        </div>
    );
  }
}

export default Contact;