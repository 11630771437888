import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function Home(props) {
  const [stats, setStats] = React.useState({success: false});

  React.useEffect(() => {
		fetch('/api/stats', {
			method: 'get',
			headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
		})
		.then(response => response.json())
    .then(setStats)
  }, []);


  return (
    stats.success &&
    <Container>
      <Row>
        <Col>
          Row count (all): {stats.countAll}
        </Col>
      </Row>
      <Row>        
        <Col>
          Row count (24h): {stats.count24h}
        </Col>
      </Row>
    </Container>
  );
}

export default Home;