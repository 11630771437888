import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { useHistory } from 'react-router-dom';

function Login(props) {
  let history = useHistory();

	const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");  
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginErrors, setLoginErrors] = useState("");

	React.useEffect(() => {
    let validationErrors = {};
  
    if(!email) {
      validationErrors.email = "Email Required";
    } 
  
    if(!password) {
      validationErrors.password = "Password Required";
    }

		setErrors(validationErrors);
	}, [email, password]);  


	async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

		const response = await fetch('/api/signin', {
			method: 'post',
      headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'},
      body: JSON.stringify({email: email, password: password})
		});
    const json = await response.json();
        
    if(json.success) {
      props.appProps.userHasAuthenticated(true);
      history.push("/");
    } else {
      setLoginErrors(json.message);
      setIsLoading(false);
    }
	}

  return (
    <Jumbotron style={{"maxWidth":"450px", "margin":"auto", "textAlign": "center", "marginTop": "50px"}}>
      <Form onSubmit={handleSubmit} style={{"maxWidth":"300px", "margin":"auto"}}> 
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
          autoFocus
          type="text"
          value={email}
          onChange={e => setEmail(e.target.value)}
          />
          {errors.email && <Form.Text>{errors.email}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          />
          {errors.password && <Form.Text>{errors.password}</Form.Text>}
        </Form.Group>
        <Button block disabled={Object.keys(errors).length !== 0 || isLoading} type="submit">
          {isLoading &&  <i className="fa fa-refresh fa-spin"></i> } &nbsp; Login
        </Button>   
        {loginErrors && <Form.Text>{loginErrors}</Form.Text>}     
      </Form>
    </Jumbotron>
  );
}

export default Login;