import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link} from 'react-router-dom';
import { Navbar, Nav } from "react-bootstrap";
import Container from 'react-bootstrap/Container';

import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Users from './Components/Users';
import NotFound from './Components/NotFound';
import Login from './Components/Login';
import TablePage from './Components/TablePage';
import PlotPage from './Components/PlotPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

function App(props) {
	const [isAuthenticated, userHasAuthenticated] = React.useState(false);
	const [isAuthenticating, setIsAuthenticating] = React.useState(true);

	React.useEffect(() => {
		fetch('/api/checkSession', {
			method: 'post',
			headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
		})
		.then(response => response.json())
		.then(data => {
			if (data.sessionValid) {
				userHasAuthenticated(true);
			} else {
				userHasAuthenticated(false);
			}
			setIsAuthenticating(false);
		});
	}, []);

	function handleLogout() {
		fetch('/api/signout', {
			method: 'post',
			headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
		})
		.then(() => userHasAuthenticated(false));		
	}	  

	return (
		!isAuthenticating &&
		<BrowserRouter>
		{isAuthenticated ?
			<>
				<Navbar bg="light" variant="light">
					<Nav className="mr-auto">
						<NavLink exact to={'/'} className="nav-link">Home</NavLink>
						<NavLink to={'/table'} className="nav-link">Table</NavLink>
						<NavLink to={'/plot'} className="nav-link">Plot</NavLink>
						<NavLink to={'/contact'} className="nav-link">Contact</NavLink>
						<NavLink to={'/about'} className="nav-link">About</NavLink>
						<NavLink to={'/users'} className="nav-link">Users</NavLink>
					</Nav>
					<Nav>
						<Link className="nav-link" onClick={handleLogout} to="#">Logout</Link>
					</Nav>
				</Navbar>
				<Container fluid>
					<Switch>
						<Route exact path='/'>
							<Home />
						</Route>
						<Route path='/table'>
							<TablePage />
						</Route>
						<Route path='/plot'>
							<PlotPage />
						</Route>
						<Route path='/contact'>
							<Contact />
						</Route>
						<Route path='/about'>
							<About />
						</Route>
						<Route exact path='/users'>
							<h3>All Users</h3>
						</Route>
						<Route path='/users/:id'>
							<Users />
						</Route>
						<Route>
							<NotFound />
						</Route>
					</Switch>
				</Container>
			</>
			:
			<Login appProps={{ isAuthenticated, userHasAuthenticated }}/>
		}
		</BrowserRouter>
	);
}

export default App;

