import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'


function TablePage(props) {
  const [city, setCity] = React.useState(null);
  const [cities, setCities] = React.useState({success: false});
  const [date, setDate] = React.useState(null);
  const [dates, setDates] = React.useState({success: false});
  const [dayData, setDayData] = React.useState({success: false});

  React.useEffect(() => {
		fetch('/api/cities', {
			method: 'get',
			headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
		})
		.then(response => response.json())
    .then(data => {setCity(data.data[0].city); return data;})
    .then(setCities);

		fetch('/api/dates', {
			method: 'get',
			headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
		})
    .then(response => response.json())
    .then(data => {setDate(data.data[0].date); return data;})
    .then(setDates);    
  }, []);

  React.useEffect(() => {
    if(city && date) {
      fetch(`/api/daydata?city=${city}&date=${date}`, {
        method: 'get',
        headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
      })
      .then(response => response.json())
      .then(setDayData);    
    }
  }, [city, date]);

  return (    
    <Container>
      <Row className='my-2'>
        <Col>
          { cities.success && dates.success && 
          <Form>
            <Form.Group>
              <Form.Row>
                <Col sm='2'>
                  <Form.Control as="select" onChange={e => setCity(e.target.value)}>
                    {cities.data.map(row => <option key={row.city}>{row.city}</option>)}              
                  </Form.Control>            
                </Col>
                <Col sm='2'>
                  <Form.Control as="select" onChange={e => setDate(e.target.value)}>
                    {dates.data.map(row => <option key={row.date}>{row.date}</option>)}              
                  </Form.Control>            
                </Col> 
              </Form.Row>
            </Form.Group>
          </Form>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          { dayData.success &&
          <Table striped bordered size='sm'>
            <thead>
              <tr>
                <th>Time</th>
                <th>Temp</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {dayData.data.map(row => 
                <tr key={row.dt.toString()}>
                  <td>{(new Date(row.dt*1000)).toISOString().substring(11,16)}</td>
                  <td>{row.temp}</td>
                  <td>{row.description}</td>
                </tr>
              )}
            </tbody>
          </Table>
          }
        </Col>
      </Row>
    </Container>
  );
}

export default TablePage;