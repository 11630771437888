
import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
        <h2>About</h2>
    );
  }
}

export default About;