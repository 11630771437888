import ChartistGraph from 'react-chartist';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'chartist/dist/chartist.css';

function TablePage(props) {
  const [city, setCity] = React.useState(null);
  const [cities, setCities] = React.useState({success: false});
  const [date, setDate] = React.useState(null);
  const [dates, setDates] = React.useState({success: false});
  const [graphData, setGraphData] = React.useState({success: false});

  React.useEffect(() => {
		fetch('/api/cities', {
			method: 'get',
			headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
		})
		.then(response => response.json())
    .then(data => {setCity(data.data[0].city); return data;})
    .then(setCities);

		fetch('/api/dates', {
			method: 'get',
			headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
		})
    .then(response => response.json())
    .then(data => {setDate(data.data[0].date); return data;})
    .then(setDates);    
  }, []);

  React.useEffect(() => {
    if(city && date) {
      fetch(`/api/daydata?city=${city}&date=${date}`, {
        method: 'get',
        headers: {'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store'}
      })
      .then(response => response.json())
      .then(dayData => setGraphData({   
        success: true,   
        labels: dayData.data.map(row => ((new Date(row.dt*1000)).toISOString().substring(11,16))),
        series: [
          dayData.data.map(row => row.temp)
        ]
      }));    
    }
  }, [city, date]);

  let options = {
    fullWidth: true,
    chartPadding: {
        right: 40
    },
    axisY: {
        onlyInteger: true,
        offset: 20
    }    
  };

  return (    
    <Container>
      <Row className='mt-2'>
        <Col>
          { cities.success && dates.success && 
          <Form>
            <Form.Group>
              <Form.Row>
                <Col sm='2'>
                  <Form.Control as="select" onChange={e => setCity(e.target.value)}>
                    {cities.data.map(row => <option key={row.city}>{row.city}</option>)}              
                  </Form.Control>            
                </Col>
                <Col sm='2'>
                  <Form.Control as="select" onChange={e => setDate(e.target.value)}>
                    {dates.data.map(row => <option key={row.date}>{row.date}</option>)}              
                  </Form.Control>            
                </Col> 
              </Form.Row>
            </Form.Group>
          </Form>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          { graphData.success &&
          <ChartistGraph className={'ct-golden-section'} data={graphData} options={options} type={'Line'} />
          } 
        </Col>
      </Row>
    </Container>
  );
}

export default TablePage;