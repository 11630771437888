import React from 'react'

function Users(props) {
    
    const { params } = props.match;

    return (
        <div>
        <h1>Users</h1>
        <p>{params.id}</p>
        </div>
    )
}
  
export default Users